// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anecdotes-js": () => import("./../../../src/pages/anecdotes.js" /* webpackChunkName: "component---src-pages-anecdotes-js" */),
  "component---src-pages-goodbye-js": () => import("./../../../src/pages/goodbye.js" /* webpackChunkName: "component---src-pages-goodbye-js" */),
  "component---src-pages-horizons-js": () => import("./../../../src/pages/horizons.js" /* webpackChunkName: "component---src-pages-horizons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-origins-js": () => import("./../../../src/pages/origins.js" /* webpackChunkName: "component---src-pages-origins-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

